import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { projects } from "../utils";

const CustomThemedDapp: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<(typeof projects)[0] | null>(null);

  useEffect(() => {
    const foundProject = projects.find(
      (p) => p.projectId === Number(projectId)
    );
    setProject(foundProject || null);
    setLoading(false);
  }, [projectId]);

  if (loading) {
    return <div className="min-vh-100 d-flex align-items-center justify-content-center bg-dark text-light">Loading...</div>;
  }

  if (!project) {
    return <div className="min-vh-100 d-flex align-items-center justify-content-center bg-dark text-light">Project not found</div>;
  }

  return (
    <div className="py-4">
      <div className="container">
        <div className="bs-card overflow-hidden">
          {/* Back Button */}
          <div className="m-3">
            <button onClick={() => window.history.back()} className="btn rounded-circle">
              <i className="bi bi-arrow-left"></i>
            </button>
          </div>

          {/* Hero Banner */}  
          <div className="p-4">
            <h1 className="display-4 fw-bold mb-0">{project.name}</h1>
            <p className="lead mb-0 text-light-50">{project.description}</p>
          </div>

          {/* Project Info */}
          <div className="p-4">
            {/* Project Icon and Title */}
            <div className="d-flex align-items-center gap-3 mb-4">
              <img 
                src={project.icon} 
                alt={project.name} 
                className="rounded-circle"
                style={{ width: '60px', height: '60px' }}
              />
              <div>
                <h2 className="h5 mb-1">{project.name}</h2>
                <p className="text-light-50 mb-0">{project.description}</p>
              </div>
            </div>

            {/* Tags */}
            <div className="d-flex gap-2 mb-4">
              <span className="badge bg-secondary">{project.track}</span>
              <span className="badge bg-secondary">Bot</span>
            </div>

            {/* Contract Address */}
            {project.name && (
              <div className="d-flex align-items-center gap-2 mb-4">
                <span className="text-light-50">Contract address:</span>
                <code className="text-light">{project.name}</code>
                <button className="btn btn-link btn-sm p-0 text-light">
                  <i className="bi bi-clipboard"></i>
                </button>
              </div>
            )}

            {/* Social Links */}
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <a href="#" className="text-light text-decoration-none">
                  <i className="bi bi-twitter fs-5"></i>
                </a>
                <a href="#" className="text-light text-decoration-none">
                  <i className="bi bi-telegram fs-5"></i>
                </a>
                <a href="#" className="text-light text-decoration-none">
                  <i className="bi bi-book fs-5"></i>
                </a>
              </div>
              
              {/* CTA Button */}
              <a href="#" className="btn btn-primary px-4 rounded-pill">
                Check it out
                <i className="bi bi-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomThemedDapp;