import React, {FC, useEffect, useState} from 'react';

interface BPaginationProps {
    totalRecords: number;
    pagination?: any;
    onPagination: (state: { page: number; limit: number }) => void;
    options?: number[];
}

const BNewPagination: FC<BPaginationProps> = ({totalRecords, onPagination, pagination, options}) => {
    const defaultOptions = [10, 25, 50, 100];
    const limitOptions = options || defaultOptions;

    const [state, setState] = useState({
        offset: pagination?.page - 1,
        limit: pagination?.limit,
    });
    const [showInput, setShowInput] = useState(false)
    const [offset, setOffset] = useState<number | any>(pagination?.page ?? 1)

    const handle = {
        page: (selectedPage: number) => {
            setState((prevState) => ({...prevState, offset: selectedPage}));
            onPagination({limit: state.limit, page: selectedPage + 1});
        },
        limit: (e: React.ChangeEvent<HTMLSelectElement>) => {
            const {value} = e.target;
            setState((prevState) => ({...prevState, limit: Number(value)}));
            onPagination({page: 1, limit: Number(value)});
        },
        offsetBlur: () => {
            setState((prevState) => ({...prevState, offset: offset - 1}))
            setShowInput(false)
            onPagination({limit: state.limit, page: offset});
        },
        offset: (e: any) => {
            const {value} = e.target;
            setOffset(Number(value) > Number(pageCount) ? pageCount : value)
        }
    };

    useEffect(() => {
        setState(prevState => ({...prevState, limit: Number(pagination?.limit)}));
    }, [pagination?.limit]);

    useEffect(() => {
        setState(prevState => ({...prevState, offset: Number(pagination?.page - 1)}));
    }, [pagination?.page]);


    if (totalRecords === 0) return null;

    const pageCount = Math.ceil(totalRecords / state.limit);

    if (isNaN(pageCount) || pageCount === Infinity) {
        return null; // Hide pagination if pageCount is NaN or Infinity
    }

    return (
        <div className="nw-pagination">
            <select name="limit" className="form-select form-select-md me-2" value={state.limit} onChange={handle.limit}>
                {limitOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
            <ul className="pagination gap-1.5">
                <li className={`page-item ${state.offset === 0 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handle.page(0)}
                        disabled={state.offset === 0}
                    >
                        First
                    </button>
                </li>
                <li className={`page-item ${state.offset === 0 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handle.page(state.offset - 1)}
                        disabled={state.offset === 0}
                    >
                        &lt;
                    </button>
                </li>
                {showInput ? <li className="page-item">
                        <input
                            type="number"
                            value={offset}
                            onChange={handle.offset}
                            onBlur={handle.offsetBlur}
                            min="0"
                            max={pageCount}
                            className="page-input"
                            id="custom-gas-limit"
                            placeholder="Page"
                        />
                    </li>
                    :
                    <li className="page-item disabled" onClick={() => setShowInput(true)}>
                        <span className="page-link" >{`${state.offset + 1} of ${pageCount}`}</span>
                    </li>
                }
                <li className={`page-item ${state.offset === pageCount - 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handle.page(state.offset + 1)}
                        disabled={state.offset === pageCount - 1}
                    >
                        &gt;
                    </button>
                </li>
                <li className={`page-item ${state.offset === pageCount - 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handle.page(pageCount - 1)}
                        disabled={state.offset === pageCount - 1}
                    >
                        Last
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default BNewPagination;
