import React, {FC, useEffect, useMemo, useState} from "react";
import {tokenService} from "../../../store/actions";
import {Link} from "react-router-dom";
import useCommon from "../../../common/hooks/useCommon";
import {
    formatNumber,
    formatUnit,
    getPlural,
    getTimeFromNow,
    getTimeLang,
    serializeQuery,
    toGwei,
    toXDCNumber,
    toXDCNumberWithoutFormat,
    truncate,
} from "../../../common/utils/function";
import AddressName from "../address-name";
import BTooltip from "../../../components/b-tooltip";
import TxStatus from "../tx-status";
import BPrice from "../b-price";
import {useTranslation} from "react-i18next";
import MethodName from "../method-name";
import BTable2 from "../../../components/b-table2";
import TokenImage from "../token-image";
import BCopy from "../../../components/b-copy";

interface XRC20TransferListData {
    tx_hash: string;
    timestamp: string | number;
    address: string;
    from: string;
    to: string;
    value: string | number;
    coingeckoid: string | number;
}

const DexTransactionAccountList: FC<any> = () => {
    const {getXDC, get0x} = useCommon();
    const [transactionList, setTransactionList] = useState<XRC20TransferListData[]>([]);
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    const [pagination, setPagination] = useState<any>({
        limit: 50,
        page: 1,
    });
    const [totalRecords, setTotalRecords] = useState<any>(0);

    const handle = {
        pagination: (payload: any) => {
            setPagination(payload);
        },
    };
    const getTransactions = async () => {
        try {
            setLoading(true);
            tokenService.getTokenTransfersList(serializeQuery({token_type: "ERC-20", ...pagination})).then((response) => {
                setTransactionList(response?.items);
                setTotalRecords(response?.count);
                setLoading(false);
            }).catch((e) => setLoading(false));
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getTransactions();
    }, [pagination]);
    const header = useMemo(() => {
        return [
            {
                title: (
                    <BTooltip text={t("See preview of the transaction details.")}>
                        <i className="bi bi-question-circle me-2 ps-2"></i>
                    </BTooltip>
                ),
                param: "header",
                render: (data: any) => (
                    <div>
                        <BTooltip
                            light={true}
                            text={
                                <React.Fragment>
                                    <div className="bs-transaction-additional-info p-2">
                                        <h2 className="bs-card-header-title">
                                            {t("Additional Info")}
                                        </h2>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">{t("Status")}:</p>
                                            <p className="bs-transaction-content">
                                                <TxStatus status={data?.status}/>{" "}
                                                {getPlural("block", data?.confirmations)}{" "}
                                                {t("confirmation")}
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">{t("Transaction Action")}:</p>
                                            <p className="bs-transaction-content">
                                                {t("Transfer")}:{" "}
                                                {formatUnit(toXDCNumber(data?.value, 18, 0))}
                                                <br/>
                                                {t("To")}:{" "}
                                                <Link to={`/address/${get0x(data?.to?.hash)}`}><AddressName data={data?.to}/></Link>
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">{t("Transaction Fee")}:</p>
                                            <p className="bs-transaction-content">
                                                {formatUnit(toXDCNumber(data?.gas_price * data?.gas_used, 18))}
                                                <BPrice
                                                    value={data?.fee?.value}
                                                    coingeckoID={process.env.REACT_APP_COINGECKO_ID}
                                                    timestamp={getTimeFromNow(data?.timestamp)}
                                                    XDCUSD="XDCUSD"
                                                    decimal={0}
                                                    type=""
                                                />
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">Gas Info:</p>
                                            <p className="bs-transaction-content">
                                                {formatNumber(data?.gas_limit || 0)} |{" "}
                                                {formatNumber(data?.gas_used || 0)}
                                                <br/>
                                                {formatUnit(toXDCNumber(data?.gas_price, 18))} (
                                                {toGwei(data?.gas_price)} Gwei)
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">{t("Nonce")}:</p>
                                            <p className="bs-transaction-content">
                                                {data?.nonce} ({t("in the position")} {data?.position})
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <Link to={`/tx/${get0x(data?.hash)}`}>
                                                {t("See more details")} &nbsp;
                                                <i className="bi bi-arrow-up-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            options={{position: ["right center", "right top", "right bottom"], on: ["click"],}}
                        >
                            <button className="btn btn-sm btn-white fs-70x content-center btn-hov" style={{width: "1.75rem", height: "1.75rem"}}><i className="bi bi-eye"></i>
                            </button>
                        </BTooltip>
                    </div>
                ),
            },
            {
                title: `${t("Tx Hash")}`,
                param: "tx_hash",
                tooltip: (data: any) => <Link to={`/tx/${get0x(data?.tx_hash)}`}>{getXDC(data?.tx_hash)}</Link>,
                render: (data: any) => <React.Fragment>
                    {data?.status === "error" || data?.status === 0 ? (
                        <strong><i className="bi bi-exclamation-circle text-danger bold" style={{fontWeight: "bold"}}></i></strong>
                    ) : null}{" "}
                    <Link to={`/tx/${get0x(data?.tx_hash)}`}>   {truncate(get0x(data?.tx_hash))}
                        &nbsp;<BCopy value={get0x(data?.tx_hash)} tooltip={`${t("Transaction Hash")}`}/></Link>
                </React.Fragment>,
            },
            {
                title: `${t("Method")}`,
                param: "method",
                tooltip: true,
                render: (data: any) => <MethodName data={data}/>,
            },
            {
                title: `${t("Age")}`,
                param: "timestamp",
                tooltip: true,
                render: (data: any) => getTimeLang(data?.timestamp, t),
            },
            {
                title: `${t("From")}`,
                param: "from",
                headerStyle: {minWidth: "130px"},
                copy: (data: any) => getXDC(data?.from?.hash),
                tooltip: (data: any) => <Link to={`/address/${get0x(data?.from?.hash)}`}>{getXDC(data?.from?.hash)}</Link>,
                render: (data: any) => <Link to={`/address/${get0x(data?.from?.hash)}`}><AddressName data={data?.from}/></Link>,
            },
            {
                title: "",
                param: "from",
                render: (data: any) => (
                    <span
                        className="bg-success bg-opacity-10 border border-success border-opacity-25 text-success fs-70x content-center rounded-pill mx-auto"
                        style={{width: "1.5rem", height: "1.5rem"}}
                    >
                        <i className="bi bi-arrow-right"></i>
                    </span>
                ),
            },
            {
                title: `${t("To")}`,
                param: "to",
                headerStyle: {minWidth: "130px"},
                copy: (data: any) => getXDC(data?.to?.hash),
                tooltip: (data: any) => <Link to={`/address/${get0x(data?.to?.hash)}`}>{getXDC(data?.to?.hash)}</Link>,
                render: (data: any) => <Link to={`/address/${get0x(data?.to?.hash)}`}><AddressName data={data?.to}/></Link>,
            },
            {
                title: `${t("Value")}`,
                param: "value",
                headerStyle: {minWidth: "130px"},
                tooltip: true,
                render: (data: any) => <React.Fragment>
                    {toXDCNumberWithoutFormat(data?.total?.value, data?.total?.decimals) * Number(data?.fiat_value ?? 1)}
                </React.Fragment>,
            },
            {
                title: `${t("Token")}`,
                param: "token",
                tooltip: true,
                render: (data: any) => (
                    <p className="text-truncate">
                        <Link to={`/token/${get0x(data?.token?.address)}`} className="d-flex align-items-center">
                            <TokenImage image={data?.token?.icon_url} symbol={data?.token?.symbol} params={{width: 20, className: "me-1"}}/>
                            {truncate(data?.token?.name || "")}({data?.token?.symbol})
                        </Link>
                    </p>
                ),
            },
        ];
    }, []);
    return (
        <div className="row">
            <div className="col-lg-12">
                <BTable2
                    title={t("Token Transactions")}
                    header={header}
                    list={transactionList}
                    loading={loading}
                    pagination={pagination}
                    totalRecords={totalRecords}
                    onPagination={handle.pagination}
                />
            </div>
        </div>
    );
};

export default DexTransactionAccountList;
