import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ethers } from "ethers";

const BroadCastTx = () => {
  const [txHex, setTxHex] = useState<string>("");
  const [transactionHash, setTransactionHash] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleBroadcast = async () => {
    setLoading(true);
    setError(null);
    setTransactionHash(null);

    try {
      // XDC Network RPC URL (replace with a reliable XDC RPC provider)
      const xdcRpcUrl = "https://erpc.apothem.network"; // https://erpc.apothem.network or another reliable RPC URL for XDC
      const provider = new ethers.JsonRpcProvider(xdcRpcUrl);

      // Broadcast the raw transaction
      const txResponse = await provider.broadcastTransaction(txHex);
      setTransactionHash(txResponse.hash);
      await txResponse.wait(); // Wait for confirmation
    } catch (err: any) {
      setError(err.message || "An error occurred while broadcasting the transaction.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="bs-page-title">Broadcast Raw Transaction</div>
        </div>
        <div className="pb-3">
          <div className="text-muted">
            <p>
              Paste your signed raw transaction in hex format below to broadcast it over the XDC network.
            </p>
          </div>
        </div>
      </div>

      <div className="bs-card p-3">
        <form>
          <div className="mb-3">
            <label className="form-label">Enter Signed Raw Transaction (Hex Format)</label>
            <textarea
              value={txHex}
              onChange={(e) => setTxHex(e.target.value)}
              placeholder="Enter Signed Raw Transaction (0x..)" 
              className="form-control font-monospace"
              style={{minHeight: "200px", resize: "vertical"}}
            />
          </div>

          <Button
            onClick={handleBroadcast}
            disabled={loading || !txHex}
            className="btn btn-primary w-100"
          >
            {loading ? "Broadcasting..." : "Broadcast Transaction"}
          </Button>
        </form>
      </div>

      {transactionHash && (
        <div className="bs-card mt-3">
          <div className="card-header">
            <h5 className="mb-0">Transaction Hash</h5>
          </div>
          <div className="card-body">
            <div className="font-monospace">
              <strong>Transaction Hash:</strong> {transactionHash}
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="alert alert-danger mt-3">
          <strong>Error:</strong> {error}
        </div>
      )}
    </React.Fragment>
  );
};

export default BroadCastTx;